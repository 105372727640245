export const FDICT_CUST = {
  CUST_ID: "0",
  NAME: "1",
  ADDRESS: "2",
  PHONE: "3",
  BILL_TO: "5",
  SALES_PERCENT: "6",
  SHIP_VIA: "7",
  REP: "8",
  ORDER_PCT: "9",
  TERMS: "10",
  CREDIT_LIMIT: "11",
  RESALE: "13",
  DISC: "14",
  NOTES: "15",
  AR_BAL: "16",
  OPEN_ORDER_BAL: "17",
  STAMP_DATE: "18",
  STAMP_ID: "19",
  CODE: "20",
  FAX: "21",
  CREDIT_HOLD: "22",
  SHIP_SEQ: "24",
  SHIP_TO: "25",
  TAX_CODES: "26",
  SORT_CITY: "27",
  SORT_STATE: "28",
  SORT_ZIP: "29",
  PARTIALS_OK: "30",
  SORT_COUNTRY: "31",
  SHIP_TO_TAX: "32",
  NAME_XREF: "33",
  CONTACT_ID: "34",
  CONTACT_TYPE: "35",
  CAMPAIGN: "38",
  MRK_OPT_OUT: "39",
  GROUP_CODE: "40",
  DATE: "41",
  GEOCODE: "43",
  SHIP_CITY: "44",
  SHIP_STATE: "45",
  SHIP_ZIP: "46",
  SHIP_GEOCODE: "47",
  CONF_NOTES: "48",
  PHONE_XREF: "49",
  PAST_DUE_DAYS: "50",
  CONTRACT_ID: "51",
  PROSPECT_ID: "52",
  ROUTE_ID: "53",
  STORE_ID: "54",
  SHIP_NAME: "55",
  SHIP_COUNTRY: "56",
  SHIP_ATTN: "57",
  SHIP_COMM: "58",
  SHIP_PHONE: "59",
  SHIP_FAX: "60",
  CREDIT_CARD_NO: "61",
  CREDIT_CARD_EXP: "62",
  CREDIT_CARD_SEC: "63",
  CREDIT_CARD_ID: "64",
  BANK_NO: "72",
  CARDHOLDER_NAME: "73",
  REBATE_TYPE: "74",
  REBATE_PCT: "75",
  REBATE_FREQ: "76",
  REBATE_ACCT: "77",
  FROM_SALES_AMT: "78",
  TO_SALES_AMT: "79",
  SALES_FREQUENCY: "101",
  SALES_ACCT: "102",
  SHIP_EMAIL: "103",
  SHIP_ACCT_NO: "105",
  SHIP_CARRIER: "106",
  CARRIER: "107",
  FRT_ACCOUNT: "108",
  THIRD_PARTY: "109",
  TP_STREET: "110",
  TP_CITY: "111",
  TP_STATE: "112",
  TP_ZIP: "113",
  TP_COUNTRY: "114",
  FREIGHT_CODE: "115",
  WEB_CUST: "116",
  QUICKLIST_NAME: "117",
  QUICKLIST_PART: "118",
  QUICKLIST_DATE: "119",
  QUICKLIST_QTY: "120",
  QUICKLIST_PRICE: "121",
  QUICKLIST_DISC: "122",
  INACTIVE_DATE: "123",
  CHANGE_DATE: "124",
  CHANGE_TIME: "125",
  CHANGED_BY: "126",
  APPROVED_BY: "127",
  CHANGE_TEXT: "128",
  CHANGE_NOTES: "129",
  CC_ADDR: "130",
  CC_CITY: "131",
  CC_STATE: "132",
  CC_ZIP: "133",
  CC_COUNTRY: "134",
  ATTACHMENTS: "135",
  ATTACHMENT_DESC: "136",
  EXPORTER: "137",
  END_USER_CNTRY: "138",
  WEBSITE: "139",
  ECOMMERCE_LEVEL: "140",
  CONTACT_PORTAL: "141",
  CURRENCY_CODE: "142",
  USE_CURRENCY: "143",
  AVATAX_DATE: "144",
  AVATAX_TIME: "145",
  BANK_ROUTING: "146",
  BANK_ACCOUNT: "147",
  ACCOUNT_TYPE: "148",
  RATE: "149",
  TYPE: "150",
  BILL_RATE_CODE: "151",
};
